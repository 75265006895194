<template>  
  <div>
    <base-header class="pb-6 pb-8 pt-5 bg-investment">
      <img class="profile-logo" src="../assets/img/profile-logo.png">
    </base-header>
    <b-container fluid class="mt--7">
      <CreateInvestment v-if="this.visualizar === 0"/>
    </b-container>
  </div>
</template>
<script>
  import CreateInvestment  from '../components/Investments/CreateInvestment'
  import EventBus from '@/bus'

  export default {
    name: 'investment',
    components:{
        CreateInvestment
    },
    data (){
        return {
            visualizar: 0
        }
    },
    created() {
        EventBus.$on('add-ruler', (item) => {
            this.visualizar = item
        })
    }
}
</script>
<style>
.el-table.table-dark{
  background-color: #172b4d;
  color: #f8f9fe;
}

.el-table.table-dark th,
.el-table.table-dark tr{
  background-color: #172b4d;
}

.el-table.table-dark td,
.el-table.table-dark th.is-leaf{
  border-bottom: none;
}
</style>
