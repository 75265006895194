<template>
    <b-card no-body>
        <div class="row my-5 mx-3">
            <div class="col-md-8 order-md-1">
                <form @submit.prevent="sendOffer">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label>Nombre(s) </label>
                            <input v-model="nombreCliente" type="text" name="js_nombre" class="form-control" required="">
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Apellido Paterno </label>
                            <input v-model="apellidoPCliente" type="text" name="js_apellido_p" class="form-control" required="">
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Apellido Materno </label>
                            <input v-model="apellidoMCliente" type="text" name="js_apellido_m" class="form-control" required="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label>Capital inicial</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input type="text" class="form-control" min="50000" step="10" v-model="monto" required="">
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Teléfono </label>
                            <input v-model="telefonoCliente" type="number" name="js_telefono" class="form-control" required="">
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Correo </label>
                            <input v-model="correoCliente" type="text" name="to_client" class="form-control" required="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-3">
                            <label>Tipo de Cliente </label>
                            <b-form-select v-model="customer" :options="customers" placeholder="" value="" required=""></b-form-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Fecha Inicio (estimado) </label>
                            <br>
                            <input type="date" v-model="fechainicio" style="width: 100%; height:50%;" required="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label>Tasa:</label>
                            <b-form-select v-model="tasavalor" :options="tasa" required=""></b-form-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Plazo de la Inversión:</label>
                            <b-form-select v-model="plazovalor" :options="plazo3" v-if="this.tasavalor.value == 15" required=""></b-form-select>
                            <b-form-select v-model="plazovalor" :options="plazo2" v-if="this.tasavalor.value == 13" required=""></b-form-select>
                            <b-form-select v-model="plazovalor" :options="plazo1" v-if="this.tasavalor.value == 12 || this.tasavalor.length === 0" required=""></b-form-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Plazo de pago:</label>
                            <b-form-select v-model="plazopagovalor" :options="plazopago1" v-if="this.tasavalor.value == 15" required=""></b-form-select>
                            <b-form-select v-model="plazopagovalor" :options="plazopago3" v-if="this.tasavalor.value == 13" required=""></b-form-select>
                            <b-form-select v-model="plazopagovalor" :options="plazopago3" v-if="this.tasavalor.value == 12 || this.tasavalor.length === 0" required=""></b-form-select>
                        </div>
                    </div>
                    <hr class="mb-4">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <input type="button" class="btn btn-primary btn-lg btn-block" value="Nueva Inversión" @click="this.cleanInvestment">
                        </div>
                        <div class="col-md-6 mb-3">
                            <input type="submit" id="sendBtn" class="btn btn-primary btn-lg btn-block" value="Enviar Cotización">
                        </div>
                    </div>
                    <hr class="mb-4">
                </form>
            </div>
            <div class="col-md-4 order-md-2">
                <h4 class="d-flex justify-content-between align-items-center">
                    <span class="text-muted">Detalles de Inversión</span>
                </h4>
                <ul class="list-group mb-3 sticky-top">
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia Proyectada Diaria</h4>
                            <small class="text-muted">Ganancia bruta al día</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(gananciaDiaria().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">ISR</h4>
                            <small class="text-muted">Reducciones ISR</small>
                        </div>
                        <span class="text-muted" v-if="this.customer.tipo==='PF' & this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(isr(20).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.customer.tipo==='PM' & this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(isr(0).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.customer.tipo===undefined || this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Fecha de Término</h4>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0">{{fechaFinMuestra()}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia Bruta</h4>
                            <small class="text-muted">Ganancia sin impuestos</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(gananciaTotal().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia Neta</h4>
                            <small class="text-muted">Ganancia con impuestos</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney((gananciaTotal()-this.isr(this.valisr)).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">IVA Causado</h4>
                            <small class="text-muted">IVA generado</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(iva().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">IVA Retenido</h4>
                            <small class="text-muted">IVA retenido para pagar</small>
                        </div>
                        <span class="text-muted" v-if="this.customer.tipo==='PF' & this.plazovalor.length !=0 & this.tasavalor.length !=0">-{{stringtoMoney(iva().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.customer.tipo==='PM' & this.plazovalor.length !=0 & this.tasavalor.length !=0">$0.00</span>
                        <span class="text-muted" v-if="this.customer.tipo===undefined || this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span> 
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Periódo {{this.plazopagovalor.text}}</h4>
                            <small class="text-muted" v-if="this.plazopagovalor.value>0 & this.plazopagovalor.value<=12">Ganancia durate el periódo</small>
                        </div>
                        <span class="text-muted" v-if="this.customer.tipo==='PF' & this.plazovalor.length !=0 & this.plazopagovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(gananciaDelPeriodo(gananciaDiaria()-(gananciaDiaria()*.20)).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.customer.tipo==='PM' & this.plazovalor.length !=0 & this.plazopagovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(gananciaDelPeriodo(gananciaDiaria()*1.16).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.customer.tipo===undefined || this.plazovalor.length ===0 || this.plazopagovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <span>Total (MEX)</span>
                        <strong v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(total().toFixed(2))}}</strong>
                        <strong v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</strong>
                    </li>
                </ul>
            </div>
            
        </div>
    </b-card>
</template>
<script>
import EventBus from '@/bus'
import {mapState, mapActions} from 'vuex'

export default {
    name: 'CreateInvestment',
    data () {
        return {
            customers:[
                {
                    value: {
                        id_customer: 1,
                        nombre: 'Persona Moral',
                        tipo: 'PM'
                    },
                    text: 'Persona Moral'
                },
                {
                    value: {
                        id_customer: 1,
                        nombre: 'Persona Física',
                        tipo: 'PF'
                    },
                    text: 'Persona Física'
                }
            ],
            visualizar: 0,
            fechainicio : null,
            //Listas de plazos de inversión
            plazo1: [
                    { value: {
                        id_term: 3,
                        text: "90 días",
                        type: "TI",
                        value: 90
                    }, text: '90 días' }
                ],
            plazo2: [
                    { value: {
                        id_term: 4,
                        text: "180 días",
                        type: "TI",
                        value: 180
                    }, text: '180 días' }
                ],
            plazo3: [
                    { value: {
                        id_term: 5,
                        text: "365 días",
                        type: "TI",
                        value: 365
                    }, text: '365 días' }
                ],
            //Tasas disponibles
            tasa: [
                    { value: {
                        id_rate: 2,
                        text: "12%",
                        type: "TI",
                        value: 12
                    }, text: '12%' },
                    { value: {
                        id_rate: 3,
                        text: "13%",
                        type: "TI",
                        value: 13
                    }, text: '13%' },
                    { value: {
                        id_rate: 5,
                        text: "15%",
                        type: "TI",
                        value: 15
                    }, text: '15%' }
                ],
            //Plazos de pago disponibles
            plazopago3: [
                    { value: {
                        id_payment_term: 1,
                        text: "Anual",
                        type: "PI",
                        value: 12
                    }, text: 'Anual' }
                ],
            plazopago2: [
                    { value: {
                        id_payment_term: 1,
                        text: "Semestral",
                        type: "PI",
                        value: 6
                    }, text: 'Semestral' }
                ],
            plazopago1: [
                    { value: {
                        id_payment_term: 1,
                        text: "Trimestral",
                        type: "PI",
                        value: 3
                    }, text: 'Trimestral' }
                ],
            plazovalor: [],
            tasavalor: [],
            plazopagovalor: [],
            customer: [],
            monto: "100,000",
            reinversion: true,
            mensaje: {color: 'success', texto: ''},
            valisr:0,
            nombreCliente: "",
            apellidoPCliente: "",
            apellidoMCliente: "",
            correoCliente: "",
            telefonoCliente: "",
        }
    },
    watch: {
        monto(){
            this.monto = this.monto.replace(/[^0-9]/g, '')
            var item = this.monto
            var x = item.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            this.monto = x1 + x2
        },
        plazovalor(){
            if(this.plazovalor.value==180 && this.plazopagovalor.value>6){
                this.plazopagovalor = []
            }
            if(this.plazovalor.value==90 && this.plazopagovalor.value>3){
                this.plazopagovalor = []
            }
            if(this.plazovalor.value==60 && this.plazopagovalor.value>2){
                this.plazopagovalor = []
            }
            if(this.plazovalor.value==30 && this.plazopagovalor.value>1){
                this.plazopagovalor = []
            }
        }
    },
    filters: {
        capitalize: function (value) {
            return value.toUpperCase()
        }
    },
    computed:{
        ...mapState(['token'])
    },
    methods: {
        ...mapActions(['cerrarSesion']),
        stringtoMoney(item){
            item += ''
            var x = item.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return '$' + x1 + x2
        },
        moneytoString(item){
            item = item.replaceAll(',','')
            return item
        },
        listPlazos(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catterms/TI', config)
            .then(res => {
                let json = []
                for (let clave in res.data){
                    json.push({text:res.data[clave].text,value: res.data[clave]})
                }
                this.plazo=json
            })
            .catch(e => {
            })
        },
        listPlazoPago(){
            let config = {
                headers: {
                    Accept: "application/json",
                }
            }
            this.axios.get('/catpaymentsterms/PI', config)
            .then(res => {
                let json = []
                for (let clave in res.data){
                    json.push({text:res.data[clave].text,value: res.data[clave]})
                }
                this.plazopago=json
            })
            .catch(e => {
            })
        },
        listTasa(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catrate/TI', config)
            .then(res => {
                let json = []
                for (let clave in res.data){
                    json.push({text:res.data[clave].text,value: res.data[clave]})
                }
                this.tasa=json
            })
            .catch(e => {
            })
        },
        //Método para envío de correo
        sendOffer(){
            var isr_json;
            var rendimiento_periodo;

            if(this.customer.tipo==='PF'){
                isr_json = this.stringtoMoney(this.isr(20).toFixed(2))
                rendimiento_periodo = this.stringtoMoney(this.gananciaDelPeriodo(this.gananciaDiaria()-(this.gananciaDiaria()*.20)).toFixed(2))
            }
            if(this.customer.tipo==='PM'){
                isr_json = "N/A"
                rendimiento_periodo = this.stringtoMoney(this.gananciaDelPeriodo(this.gananciaDiaria()*1.16).toFixed(2))
            }

            //Se genera el json a mandar a la API
            let json = {
                "cliente" : this.nombreCliente.toUpperCase()+" "+this.apellidoPCliente.toUpperCase()+" "+this.apellidoMCliente.toUpperCase(),
                "monto_inicial" : "$"+this.monto.toString()+".00",
                "forma_pago" : this.plazopagovalor.text,
                "tasa" : this.tasavalor.text,
                "plazo" : this.plazovalor.text,
                "rendimiento_bruto" : this.stringtoMoney(this.gananciaTotal().toFixed(2)),
                "rendimiento_neto" : this.stringtoMoney((this.gananciaTotal()-this.isr(this.valisr)).toFixed(2)),
                "isr" : isr_json,
                "total" : this.stringtoMoney(this.total().toFixed(2)),
                "periodo_pago" : this.plazopagovalor.text,
                "rendimiento_periodo" : rendimiento_periodo,
                "monto_minimo" : "$100,000.00",
                "plazo_minimo" : "30 días",
                "fecha_inicio" : this.fechaInicio(),
                "currentDate" : this.fechaActual(),
                "toMail": this.correoCliente,
                "telefono": this.telefonoCliente
            }

            let config = {
                    headers: {
                        Accept: "application/json",
                    }
                }
            //Se hace la petición
            this.axios.post('/send', json, config)
                .then(res => {
                    this.mensaje.color = 'success'
                    this.mensaje.texto = '¡Se ha enviado a su correo!'
                    this.alerta(this.mensaje)
            })
                .catch(e => {
                    alert("Error al mandar propuesta")
            })
        },
        cleanInvestment(){
            this.plazopagovalor = []
            this.plazovalor = []
            this.customer = []
            this.tasavalor = []
            this.fechainicio = null
            this.monto = '100,000'
            this.nombreCliente = ""
            this.apellidoPCliente = ""
            this.apellidoMCliente = ""
            this.correoCliente = ""
            this.telefonoCliente = ""
        },
        createInvestment(){
            this.plazopagovalor = []
            this.plazovalor = []
            this.customer = []
            this.tasavalor = []
            this.fechainicio = null
            this.monto = '100,000'
        },
        getInvestments() {
            this.visualizar = 0
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        alerta(item) {
            EventBus.$emit('alerta', item)            
        },
        fechaInicio(){
            let initialDate = new Date(this.fechainicio + 'T00:00:00')
            let a = initialDate.getFullYear()
            let d = '' + initialDate.getDate()
            let m = '' + (initialDate.getMonth() + 1)
            if( m.length < 2 ){
                m = '0' + m
            }
            if( d.length < 2 ){
                d = '0' + d
            }
            return d + '/' + m + '/' + a
        },
        fechaActual(){
            let currentDate = new Date()
            let a = currentDate.getFullYear()
            let d = '' + currentDate.getDate()
            let m = '' + (currentDate.getMonth() + 1)
            if( m.length < 2 ){
                m = '0' + m
            }
            if( d.length < 2 ){
                d = '0' + d
            }
            if(m=="01"){m = "Enero"}
            else if(m=="02"){m = "Febrero"}
            else if(m=="03"){m = "Marzo"}
            else if(m=="04"){m = "Abril"}
            else if(m=="05"){m = "Mayo"}
            else if(m=="06"){m = "Junio"}
            else if(m=="07"){m = "Julio"}
            else if(m=="08"){m = "Agosto"}
            else if(m=="09"){m = "Septiembre"}
            else if(m=="10"){m = "Octubre"}
            else if(m=="11"){m = "Noviembre"}
            else if(m=="12"){m = "Diciembre"}


            return "Guadalajara, Jal. A "+d+" de "+m+" del "+a
        },
        fechaFin() {
            if(this.fechainicio != null){
                let fechafin = new Date(this.fechainicio + 'T00:00:00')
                fechafin.setDate(fechafin.getDate() + this.plazovalor.value)
                let a = fechafin.getFullYear()
                let d = '' + fechafin.getDate()
                let m = '' + (fechafin.getMonth() + 1)
                if( m.length < 2 ){
                    m = '0' + m
                }
                if( d.length < 2 ){
                    d = '0' + d
                }
                return a + '-' + m + '-' + d
            }
        },
        fechaFinMuestra() {
            if(this.fechainicio != null){
                let fechafin = new Date(this.fechainicio + 'T00:00:00')
                fechafin.setDate(fechafin.getDate() + this.plazovalor.value)
                let a = fechafin.getFullYear()
                let d = '' + fechafin.getDate()
                let m = '' + (fechafin.getMonth() + 1)
                if( m.length < 2 ){
                    m = '0' + m
                }
                if( d.length < 2 ){
                    d = '0' + d
                }
                return d + '/' + m + '/' + a
            }
        },
        gananciaDiaria(){
            switch (this.plazovalor.value) {
                case 0:
                    return 0
                    break;
                default:
                    return this.gananciaTotal()/this.plazovalor.value
                    break;
            }
        },
        gananciaTotal(){
            return (this.plazovalor.value/365)*this.tasavalor.value*this.moneytoString(this.monto)/100
        },
        isr(val){
            return (parseInt(val)*this.gananciaTotal())/100
        },
        iva(){
            return (this.gananciaTotal()*16)/100
        },
        total(){
            
            switch (this.customer.tipo) {
                case "PF":
                    this.valisr = 20;
                    return parseFloat(this.moneytoString(this.moneytoString(this.monto))) + this.gananciaTotal() - this.isr(this.valisr)
                    break;
                case "PM":
                    this.valisr = 0;
                    return parseFloat(this.moneytoString(this.monto)) + this.gananciaTotal() - this.isr(this.valisr) +this.iva()
                    break;
                default:
                    this.valisr = 0
                    return parseFloat(this.moneytoString(this.monto)) + this.gananciaTotal() - this.isr(this.valisr)
                    break;
            }
            
        },
        gananciaDelPeriodo(gananciaDiaria){
            return (gananciaDiaria * 30.4166667) * this.plazopagovalor.value
        },
        listCustomers(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catcustomers', config)
            .then(res => {
                let a = null
                this.customers.push({
                    value: {
                        id_customer: 1,
                        nombre: 'Persona Moral',
                        tipo: 'PM'
                    },
                    text: 'Persona Moral'
                })
                this.customers.push({
                    value: {
                        id_customer: 1,
                        nombre: 'Persona Física',
                        tipo: 'PF'
                    },
                    text: 'Persona Física'
                })
            })
            .catch(e => {
                if(e.response.status === 403){
                    this.cerrarSesion()
                }
            })
        },
    }
}


</script>